(<template>
  <div class="feedback-tools">
    <div class="accordion__header--opened">
      <div class="default-accordion__header feedback-tools__header">{{ $gettext('Confirmed decision to finance KS') }}</div>
    </div>
    <div class="default-accordion__content feedback-tools__content">
      <order-info-section class="feedback-tools__section" />
      <span class="feedback-tools__delimiter"></span>
      <change-category-section v-if="lockedJob && showChangeCategoryBlock"
                               class="feedback-tools__section" />
      <span class="feedback-tools__delimiter"></span>
      <travel-expenses-section v-if="showTravelExpensesBlock"
                               :admin-mode="true"
                               class="feedback-tools__section" />
      <span class="feedback-tools__delimiter"></span>
      <change-assignment-type-section class="feedback-tools__section" />
    </div>
  </div>
</template>)

<script>
  import OrderInfoSection from './OrderInfoSection';
  import ChangeAssignmentTypeSection from './ChangeAssignmentTypeSection';
  import ChangeCategorySection from './ChangeCategorySection';
  import TravelExpensesSection from '../feedback_sections/travel_expenses_section/TravelExpensesSection';

  export default {
    components: {
      'order-info-section': OrderInfoSection,
      'change-assignment-type-section': ChangeAssignmentTypeSection,
      'change-category-section': ChangeCategorySection,
      'travel-expenses-section': TravelExpensesSection
    },
    props: {
      showTravelExpensesBlock: Boolean,
      showChangeCategoryBlock: Boolean,
      lockedJob: Boolean
    }
  };
</script>

<style src="@assets/css/default_accordion.css"></style>

<style>
  .feedback-tools .post-form__section--underline::after {
    background-color: #e9e9ee;
  }

  .feedback-tools .post-form__section--underline:last-child::after {
    height: 0;
  }
</style>

<style scoped>
  .feedback-tools__content {
    background-color: #fff;
  }

  .feedback-tools__header {
    overflow: auto;
  }

  .feedback-tools__delimiter {
    position: relative;
    left: 30px;
    z-index: 2;
    display: block;
    width: calc(100% - 60px);
    height: 0.5px;
    background-color: #e9e9ee;
  }
</style>
